@import "bootstrap/scss/bootstrap";
@import "bootstrap/dist/css/bootstrap.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import "./assets/common-styles/variable.scss";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');


.default-form-group-border {
  border-radius: 2px !important;
  box-shadow: none !important;
}

.default-form-group-border:focus {
  border-color: lightgray !important;
  // border: none !;
}

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('../src/assets/common-styles/gilroy/Gilroy-Bold.ttf') format("truetype");
}

@font-face {
  font-family: 'Gilroy-Light';
  src: url('../src/assets/common-styles/gilroy/Gilroy-Light.ttf') format("truetype");
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: url('../src/assets/common-styles/gilroy/Gilroy-Regular.ttf') format("truetype");
}


body {
  font-family: 'Gilroy-Regular', Gilroy-Regular;
}